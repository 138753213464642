import { h, Component } from 'preact';
import { route } from 'preact-router'; // For navigation
import ErrorAlert from '../components/ErrorAlert/ErrorAlert';

class LoginPage extends Component {
  state = {
    username: '',
    password: '',
    loading: false,
    error: null,
    statusCode: null, // Store the HTTP status code
  };

  // Handle form field changes
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // Handle form submission
  handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page reload on form submit

    const { username, password } = this.state;

    // Check if username and password are entered
    if (!username || !password) {
      this.setError('Please enter both username and password', null);
      this.setState({ loading: false }); // Ensure loading is reset
      return;
    }

    this.setState({ loading: true, error: null, statusCode: null });

    try {
      const response = await fetch(
        `https://carriage.runlevelfive.net/api/login?username=${username}&password=${password}`
      );

      if (!response.ok) {
        // Check for specific HTTP status codes or server response
        if (response.status === 401) {
          throw {
            message: 'Incorrect username or password',
            statusCode: response.status,
          };
        } else if (response.status === 500) {
          throw {
            message: 'Server error, please try again later',
            statusCode: response.status,
          };
        } else {
          throw { message: `Unexpected error`, statusCode: response.status };
        }
      }

      const data = await response.json();

      // Store token in localStorage
      localStorage.setItem('token', JSON.stringify(data));

      console.log('Login successful:', data);

      // Redirect to the desired URL
      const redirectUrl =
        new URLSearchParams(window.location.search).get('redirect') || '/ui';
      console.log(`Redirecting back to: ${redirectUrl}`);

      route(redirectUrl);
    } catch (error) {
      console.error('Error during login:', error);
      this.setError(error.message, error.statusCode);
    } finally {
      // Ensure loading is reset regardless of success or failure
      this.setState({ loading: false });
    }
  };

  // Set error with message and status code
  setError = (message, statusCode) => {
    this.setState({ error: message, statusCode });
  };

  // Clear the error message
  clearError = () => {
    this.setState({ error: null, statusCode: null });
  };

  render() {
    const { username, password, loading, error, statusCode } = this.state;

    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          <div class='form-field'>
            <label>
              <span class='form-field-name'>Username:</span>
              <input
                type='text'
                name='username'
                value={username}
                onInput={this.handleInputChange}
                required
              />
            </label>
          </div>
          <div>
            <label>
              <span class='form-field-name'>Password:</span>
              <input
                type='password'
                name='password'
                value={password}
                onInput={this.handleInputChange}
                required
              />
            </label>
          </div>
          <div>
            <button type='submit' disabled={loading} class='btn-primary'>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>

        {error && (
          <ErrorAlert
            message={error}
            statusCode={statusCode}
            clearError={this.clearError}
          />
        )}
      </div>
    );
  }
}

export default LoginPage;
