import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { ThemeContext } from './ThemeProvider';

const ThemeToggle = ({ className: additionalClass }) => {
  const { theme, toggleTheme, class: contextClass } = useContext(ThemeContext);

  const finalClassName = `${contextClass || ''} ${
    additionalClass || ''
  }`.trim();

  return (
    <button onClick={toggleTheme} class={finalClassName}>
      Switch to {theme === 'dark' ? 'light' : 'dark'} mode
    </button>
  );
};

export default ThemeToggle;
