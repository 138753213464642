import { h } from 'preact';
import { Link } from 'preact-router';
import './breadcrumbs.css';

const Breadcrumbs = ({ path, routes }) => {
  const breadcrumbs = [];
  let fullPath = '';

  path
    .split('/')
    .filter(Boolean) // Remove empty segments
    .forEach((segment, index) => {
      fullPath += `/${segment}`;

      // Match the current fullPath against the regex in the pathMapping
      const matchedRoute = Object.keys(routes).find((route) => {
        const routeRegex = new RegExp(route); // Convert the route string into a regex
        return routeRegex.test(fullPath);
      });

      if (matchedRoute) {
        // Extract parameter values using the regex
        const match = fullPath.match(new RegExp(matchedRoute));
        const params = match?.groups || {};

        // Replace placeholders (e.g., {camera}) with the actual value
        const label = routes[matchedRoute].replace(
          /\{(\w+)\}/g,
          (_, key) => params[key] || key
        );

        const isLast = index === path.split('/').filter(Boolean).length - 1;

        breadcrumbs.push(
          <span key={fullPath} class='breadcrumb-item'>
            {isLast ? (
              <span>{label}</span>
            ) : (
              <Link href={fullPath}>{label}</Link>
            )}
          </span>
        );
      }
    });

  return (
    <div class='breadcrumbs-container'>
      <div class='breadcrumbs'>
        {breadcrumbs.map((breadcrumb, index) => (
          <div key={index} class='breadcrumb'>
            {breadcrumb}
            {index < breadcrumbs.length - 1 && <span> / </span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
