import { h, Component } from 'preact';
import { route } from 'preact-router';
import { fetchData } from '../api';
import '../components/spinner.css';

class HomePage extends Component {
  state = {
    cameraImages: [],
    loading: true,
  };

  // Run when the component mounts
  fetchCameraImages = async () => {
    try {
      const cameras = await fetchData(
        'https://carriage.runlevelfive.net/api/cameras'
      );

      const cameraImages = await Promise.all(
        cameras.map(async (camera) => {
          try {
            const cameraResponse = await fetchData(
              `https://carriage.runlevelfive.net/api/cameras/${camera}/images/latest`
            );

            return { camera: camera, image: atob(cameraResponse.url) };
          } catch (error) {
            alert(error);
            return { camera: camera, image: null }; // In case the image doesn't load
          }
        })
      );

      this.setState({ cameraImages: cameraImages, loading: false });
    } catch (error) {
      if (error.message === 'Unauthorized') {
        this.setState({ loading: false });

        const currentUrl = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        route(`/ui/login?redirect=${currentUrl}`);
      } else {
        console.error(error);
        alert(`Error fetching api: ${error}`);
        this.setState({ loading: false });
      }
    }
  };

  componentDidMount() {
    this.fetchCameraImages();
    this.interval = setInterval(this.fetchCameraImages, 30000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval); // Clear the interval to prevent memory leaks
    }
  }

  handleLogout = () => {
    localStorage.removeItem('token');
    alert('Logged out');
  };

  handleRefresh = () => {
    this.fetchCameraImages();
  };

  handleEvents = (camera) => {
    route(`/ui/events/${camera}`);
  };

  render() {
    const { cameraImages, loading, data } = this.state;

    if (loading) {
      return (
        <div className='spinner-container'>
          <div className='spinner'></div>
        </div>
      );
    }

    return (
      <div>
        <button onClick={this.handleRefresh} class='btn-secondary'>
          Refresh Images
        </button>
        <div class='camera-display'>
          {cameraImages.map(({ camera, image }) => (
            <div key={camera} class='camera'>
              <div class='camera-info'>
                <h3>{camera}</h3>
                <button onClick={this.handleHistory} class='btn-secondary'>
                  Image History
                </button>
                <button
                  onClick={() => this.handleEvents(camera)}
                  class='btn-secondary'
                >
                  Events
                </button>
              </div>
              {image ? (
                <img src={image} alt={`Latest image from ${camera}`} />
              ) : (
                <p>No image available for {camera}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default HomePage;
