import { h, Component } from 'preact';
import './erroralert.css';

class ErrorAlert extends Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.setState({ visible: true });

    // Automatically fade out and remove the error after 5 seconds
    setTimeout(() => {
      this.setState({ visible: false });
      setTimeout(() => {
        this.props.clearError(); // Notify parent to clear the error state
      }, 300); // Match fade-out duration
    }, 3000);
  }

  render() {
    const { message, statusCode } = this.props;
    const { visible } = this.state;

    return (
      <div
        class={`login-error ${visible ? 'fade-in' : 'fade-out'}`}
        style={{
          opacity: visible ? 1 : 0,
        }}
      >
        {message}
        {statusCode && (
          <div>Error Code: {statusCode}</div>
        )}
      </div>
    );
  }
}

export default ErrorAlert;
