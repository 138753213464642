export const fetchData = async (url) => {
	const storedToken = localStorage.getItem("token");

	const tokenObject = storedToken ? JSON.parse(storedToken) : null;
	const token = tokenObject ? tokenObject.token : "";

	const headers = {
		"Content-Type": "application/json",
		"Authorization": `Bearer ${token}`
	};

	const response = await fetch(url, {
		headers: headers,
	});

	if (response.status === 401) {
		// Return 401 error so we can handle it in the component
		throw new Error('Unauthorized');
	}

	return await response.json();
};
