import { h, Component } from 'preact';
import { route } from 'preact-router';
import { fetchData } from '../api';
import '../components/spinner.css';

class EventPage extends Component {
  state = {
    events: [],
    loading: true,
    currentPage: 0,
    pageCount: 0,
  };

  fetchEventData = async (page = 0) => {
    try {
      const camera = this.props.camera;
      console.log('Camera ID:', camera);
      console.log('Page:', page);

      const events = await fetchData(
        `https://carriage.runlevelfive.net/api/cameras/${camera}/clips?pageSize=25&page=${page}`
      );

      this.setState({
        events: events,
        loading: false,
        currentPage: page,
        pageCount: events.page_count,
      });
    } catch (error) {
      if (error.message === 'Unauthorized') {
        const currentUrl = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        route(`/ui/login?redirect=${currentUrl}`);
      } else {
        console.error(error);
        alert(`Error fetching api: ${error}`);
        this.setState({ loading: false });
      }
    }
  };

  componentDidMount() {
    this.fetchEventData();
  }

  handlePageChange = (page) => {
    if (page >= 0 && page <= this.state.pageCount) {
      this.setState({ loading: true });
      this.fetchEventData(page);
    }
  };

  render() {
    const { events, loading, currentPage, pageCount } = this.state;

    if (loading) {
      return (
        <div className='spinner-container'>
          <div className='spinner'></div>
        </div>
      );
    }

    return (
      <div>
        <h2>
          Event Table (Page {currentPage} of {events.page_count})
        </h2>
        <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th>Thumbnail</th>
              <th>Label</th>
              <th>Time</th>
              <th>Size</th>
              <th>Clip</th>
            </tr>
          </thead>
          <tbody>
            {events?.events?.map((event, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={`data:image/jpeg;base64,${event.thumbnail}`}
                    alt={event.label}
                    style={{ width: '128px', height: '128px' }}
                  />
                </td>
                <td>{event.label}</td>
                <td>{event.time}</td>
                <td>{event.size}</td>
                <td>
                  <a href={event.url}>View Clip</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div class='pagination'>
          <button
            onClick={() => this.handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            class='btn-primary'
          >
            Previous
          </button>
          {[...Array(pageCount + 1).keys()].map((page) => (
            <button
              key={page}
              onClick={() => this.handlePageChange(page)}
              disabled={page === currentPage}
              class='btn-secondary'
              style={{
                margin: '0 5px',
                fontWeight: currentPage === page ? 'bold' : 'normal',
                color: currentPage === page ? 'var(--pagination-color)' : '',
                backgroundColor:
                  currentPage === page ? 'var(--pagination-bgcolor)' : '',
              }}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => this.handlePageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
            class='btn-primary'
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default EventPage;
