import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '80px',
          right: '20px',
          padding: '10px 15px',
          fontSize: '16px',
          borderRadius: '50%',
          background: '#007bff',
          color: '#fff',
          border: 'none',
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
          cursor: 'pointer',
        }}
      >
        ↑
      </button>
    )
  );
};

export default ScrollToTop;
